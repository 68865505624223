import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import BannerLanding from '../components/BannerEvents'

import pic08 from '../assets/imgs/landing/ateneu.jpg'
import pic11 from '../assets/imgs/landing/tar.jpg'
import pic12 from '../assets/images/turin.jpeg'

import pic09 from '../assets/images/pic09.jpg'
import pic10 from '../assets/images/pic10.jpg'

const Landing = (props) => (
    <Layout>
        <Helmet>
            <title>Unidans Project</title>
            <meta name="description" content="Landing Page" />
        </Helmet>

        <BannerLanding />

        <div id="main">
            <section id="one">
                <div className="inner">
                    {/* <header className="major">
                        <h2>TODO</h2>
                    </header>
                    <p>TOOD</p> */}
                </div>
            </section>
              <section id="two" className="spotlights">
              <section>
                    <Link to="/generic" className="image">
                        <img src={pic12} alt="" />
                    </Link>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Transnational Meeting, 7 - 8th of February 2020, Turin (Italy)</h3>
                            </header>
                            <a target= "_blank" href = "https://drive.google.com/open?id=1qjUkIfMGB81vSJGHHKgboQL9HD74cSSe" > Agenda  </a>
                           
                        </div>
                        
                    </div>
                </section>
                
                <section>
                    <Link to="/generic" className="image">
                        <img src={pic11} alt="" />
                    </Link>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Transnational Meeting, 16th - 18th of October 2019, <br/> Tarrega (Spain)</h3>
                                <a href = "https://aalba.cat/ca/c/europa-unidans-244"> Events </a>
                            </header>
                            {/* <a target= "_blank" href = "https://drive.google.com/open?id=1V9GRC9dXw7sSqjX0LLuNiXNHQLCkVJpZ" > Agenda Kick off  </a>
                            <p> The first meeting of the partners coordinators and specialists will be organized at Bucharest (Romania) 
The management and the implementation issues will be discussed in order to have a fruitful cooperation and a successful project.  </p>
                            <p>  The main events will be: </p>
                            <ul>

                                <li> LOCAL PRESS CONFERENCE to launch the project </li>
                                <li> UNIDANS NETWORKING EVENT  IN ROMANIA with representatives of the NGOS/institutions/local clubs in the domain of unified sport/dance</li>
                                <li> UNIDANS TRAINING SEMINAR IN ROMANIA with 20 local coaches/sport/dance teachers   </li>
                                <li> UNIDANS OPEN CLASS IN ROMANIA where coaches from Romania will conduct a unified sport/dance lesson with a local mixed group </li>
                            </ul> */}
                        </div>
                        
                    </div>
                </section>
                <section>
                    <Link to="/generic" className="image">
                        <img src={pic08} alt="" />
                    </Link>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Kick off meeting, 25 - 27th of March, Bucharest</h3>
                            </header>
                            <a target= "_blank" href = "https://drive.google.com/open?id=1V9GRC9dXw7sSqjX0LLuNiXNHQLCkVJpZ" > Agenda Kick off  </a>
                            <p> The first meeting of the partners coordinators and specialists will be organized at Bucharest (Romania) 
The management and the implementation issues will be discussed in order to have a fruitful cooperation and a successful project.  </p>
                            <p>  The main events will be: </p>
                            <ul>

                                <li> LOCAL PRESS CONFERENCE to launch the project </li>
                                <li> UNIDANS NETWORKING EVENT  IN ROMANIA with representatives of the NGOS/institutions/local clubs in the domain of unified sport/dance</li>
                                <li> UNIDANS TRAINING SEMINAR IN ROMANIA with 20 local coaches/sport/dance teachers   </li>
                                <li> UNIDANS OPEN CLASS IN ROMANIA where coaches from Romania will conduct a unified sport/dance lesson with a local mixed group </li>
                            </ul>
                        </div>
                        
                    </div>
                </section>
                {/* <section>
                    <Link to="/generic" className="image">
                        <img src={pic09} alt="" />
                    </Link>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Rhoncus magna</h3>
                            </header>
                            <p>Nullam et orci eu lorem consequat tincidunt vivamus et sagittis magna sed nunc rhoncus condimentum sem. In efficitur ligula tate urna. Maecenas massa sed magna lacinia magna pellentesque lorem ipsum dolor. Nullam et orci eu lorem consequat tincidunt. Vivamus et sagittis tempus.</p>
                            <ul className="actions">
                                <li><Link to="/generic" className="button">Learn more</Link></li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section>
                    <Link to="/generic" className="image">
                        <img src={pic10} alt="" />
                    </Link>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Sed nunc ligula</h3>
                            </header>
                            <p>Nullam et orci eu lorem consequat tincidunt vivamus et sagittis magna sed nunc rhoncus condimentum sem. In efficitur ligula tate urna. Maecenas massa sed magna lacinia magna pellentesque lorem ipsum dolor. Nullam et orci eu lorem consequat tincidunt. Vivamus et sagittis tempus.</p>
                            <ul className="actions">
                                <li><Link to="/generic" className="button">Learn more</Link></li>
                            </ul>
                        </div>
                    </div> 
                </section> */}
            </section> 
        </div>

    </Layout>
)

export default Landing